import React from "react";
import Layout from "../components/layout";

export default function Home() {
  return (
    <Layout>
      <p style={{ paddingTop: "0.5rem", 'grid-column': 'span 2' }}>
          Miskin Software in an independent software consultancy based in the
          historic city of Bath, founded in 2014 by Richard Miskin. We can
          support the design and implementation of complex software and are 
          strong advocates of agile development practices and the value of
          continuous improvement.
      </p>
    </Layout>
  );
}
